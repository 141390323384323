@import 'styles/variables.scss';
@import 'styles/animations.scss';

.banner {
  animation: subtle-drop 0.2s;
  background: $color-pink;
  color: #fff;
  min-height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #fff;
  position: relative;
  top: 52px;
  font-size: 0.85rem;
  z-index: 10;

  @media (min-width: $breakpoint-small) {
    font-size: 0.9rem;
    top: 0;
    z-index: 1;
    min-height: 46px;
  }

  a {
    color: #fff;
    text-decoration: underline;
    white-space: nowrap;
  }
}

.bannerContent {
  max-width: 87.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.75rem;
  align-items: center;
  top: 0.5rem;

  @media (min-width: $breakpoint-small) {
    padding: 0.5rem 0.75rem;
  }

  @media (min-width: 1200px) {
    padding: 0.5rem 3rem;
  }
}

.bannerIcon {
  min-width: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}
